import React from 'react';
import Übersicht from "./Komponenten/Übersicht";
import './App.css';
import { Helmet } from 'react-helmet';
function App() {
  return (


    <div  className="App">
              <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        </Helmet>
     <Übersicht />
    </div>

  );
}

export default App;
