// Modal from MUI
import Box from '@mui/material/Box';
import React,  {useState , useEffect} from 'react';
import axios from 'axios';
import { Modal} from '@mui/material';
import "./Grundeinstellung.css"
import CurrencyInput from 'react-currency-input-field';

const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Grundeinstellung(props) {

    const [Settings , setSettings] = useState(

        {
            MWST : true,
            Steuersatz : null,
            Firmenname : null,
            Adresse : null,
            Telefonnummer : null,
            "Email-Adresse" : null,
            Homepage : null,
            Host : null,

        }
    );

    const getSettings =async () => {

        let tmp_Settings = {}


        for (const item in Settings){
            // get Key
            let key = item
            let response = await axios.get(`/api/Settings?Wert=${key}`);
            tmp_Settings[key] = response.data;
            

        }
        setSettings(tmp_Settings);

    }
    useEffect(() => {
        if (props.open){
            getSettings();
        }else{
            
        }
    },[props.open ]);

return (


    <div >

        <Modal open={props.open} onClose={() => {props.close()}}>
        <Box sx={style}>
            {Settings === undefined ? <div>loading</div> :
            <div>
                <h1>Grundeinstellung</h1>
                <div className="Grundeinstellung" >
                { Object.keys(Settings).map((item) => {
                    return (
                        <div key={`DIV-${item}`}>
                            <label>{item}</label>
                            {!item.includes("MWST")  ?
                            (item.includes("satz"))
                             ? 
                             <CurrencyInput key={item + Settings[item]}  defaultValue={Settings[item]}  onChange={
                                (event) => {
                                    let TEst = {
                                        Name : item,
                                        // to float the value
                                        Wert :  parseFloat(event.target.value.replace((","), "."))
                                    }
                                    axios.post("/api/Settings", TEst).then((response) => {
                                        console.log(response);
                                    }
                                    ).catch((error) => {
                                        console.log(error);
                                    }
                                    )
                                }
                             }/>
                             :
                            
                            <input key={item} type="text" defaultValue={Settings[item]} onChange={(event) =>{
                                // axios posz
                                let TEst = {
                                    Name : item,
                                    Wert : event.target.value
                                }
                                axios.post("/api/Settings", TEst).then((response) => {
                                    console.log(response);
                                }
                                ).catch((error) => {
                                    console.log(error);
                                }
                                )

                            }} /> :

                            React.createElement("input", {key: item +  Settings[item], type: "checkbox", defaultChecked: Settings[item], onChange: (event) =>{

                                let TEst = {
                                    Name : item,
                                    Wert : event.target.checked
                                }
                                axios.post("/api/Settings", TEst).then((response) => {
                                    console.log(response);
                                }
                                ).catch((error) => {
                                    console.log(error);
                                }
                                )
                            }})

                          
                        }
                        </div>
                    )})}



                </div>
            </div>
}
</Box>
        </Modal>

    </div>
)
}