
import React  from "react";
import axios from "axios";
import "./Profil_Einstellung.css";


export default function Einstellung (probs) {



    return (
        <div className="Einstellung">
            <div className="Usersettings">
                
                        <form autocomplete="off" onSubmit={
                            (event) => {
                                event.preventDefault();
                                if (event.target.elements.Passwort.value !== event.target.elements["Passwort wiederholen"].value) {
                                    alert("Passwörter stimmen nicht überein");
                                    return;
                                }
                                const data = {
                                    Username: event.target.elements.Benutzername.value,
                                    OldPassword: event.target.elements.altesPasswort.value,
                                    NewPassword: event.target.elements.Passwort.value,

                                    
                                }

                                axios.post("/api/User/update", data).then((response) => {
                                    console.log(response.data);
                                    probs.logout()
                                })
                            }
                        }>
                        <label>Benutzername
                            <input disabled type="text" name="Benutzername"  defaultValue={probs.User.Username} placeholder="Benutzername"/>
                        </label>
                        <label>E-mail
                            <input disabled type="text" name="E-mail"  defaultValue={probs.User.Email} placeholder="E-mail"/>
                        </label>
                        <label>altes Passwort
                        <input type="text" name="altesPasswort" placeholder="altes Passwort"/>
                        </label>
                        <label>neues Passwort
                            <input type="text" name="Passwort" placeholder="Passwort"/>
                        </label>
                        <label>Passwort wiederholen
                            <input type="text" name="Passwort wiederholen" placeholder="Passwort wiederholen"/>
                        </label>
                        <label>Benutzergruppe
                            <input type="text"  defaultValue={probs.User.Gruppe} disabled name="Benutzergruppe" placeholder="Benutzergruppe"/>
                        </label>
                        <input type="submit" value="Speichern" />
                        </form>


            </div>


        </div>
    );
}