import React from "react"
import "./login.css"
import axios from "axios"

function Login (props) {
  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={

        (event) => {
          event.preventDefault();
            let username = event.target[0].value;
            let password = event.target[1].value;
            axios.post("/api/login", {
                username: username,
                password: password
            }).then((response) => {
                props.setUser(response.data);
            }).catch((response , error) => {
                response.response.status === 401 ? alert("Falsche Login-Daten") :
                console.log(error);
            })

      }}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Anmelden</h3>
          <div className="form-group mt-3">
            <label>Benutzername</label>
            <input
              autocapitalize="none"
              type="text"
              className="form-control mt-1"
              placeholder="Benutzername eingeben"
            />
          </div>
          <div className="form-group mt-3">
            <label>Passwort</label>
            <input
                          autocapitalize="none"

              type="password"
              className="form-control mt-1"
              placeholder="Passwort eingeben"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button  type="submit" className="btn btn-primary">
              anmelden
            </button>
          </div>
          
        </div>
      </form>
    </div>
  )
}

export default Login