import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import React, { useState , useRef , useEffect } from 'react';
import "./Artikel.css"
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Artikel(props) {

const [count, setCount] = useState(1);
const [Button_hinzufügen, setButton_hinzufügen] = useState(true);
const handleClose = () => {
    setButton_hinzufügen(true);
    setVermietungs_Artikel(true)
    setCount(1);
    props.close()}
const [Artikel, setArtikel] = useState(undefined);
const [vermietungs_Artikel , setVermietungs_Artikel] = useState(true);


    useEffect(() => {
        Artikel_abrufen();
    },[])

    const Artikel_abrufen = () => {
        axios.get("/api/Produkte", {credentials: "same-origin"
        }).then((response) => {
            setArtikel(response.data);
        }).catch((error) => {
            console.log(error);
            setArtikel(undefined);
        })}





  return (
    <div>

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.details.current !== undefined ? props.details.current.name : "Kein Name"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>

            
            { Artikel !== undefined ?
            <div className='Artikel_hinzufügen'>
                <form id="Artikel" onSubmit={(event)=>{
                 event.preventDefault();
                 let Artikel = parseInt(event.target[0].selectedOptions[0].getAttribute('data-artikel_id'))
                 let uuid = props.details.current.uuid;
                 let Anzahl = count;
                 axios.post("/api/Vermietung",{
                    uuid : uuid,
                    Anzahl : Anzahl,
                 Artikelid : Artikel}).then((response) => {
                    props.close();
                    setCount(1);
                    setVermietungs_Artikel(true);
                    setButton_hinzufügen(true);
                }).catch((error) => {
                    console.log(error);
                })
                console.log("Hallo");
            }}>
                <select onChange={(event) =>{
                    if(event.target.selectedOptions[0].getAttribute('data-vermietung') === "true"){
                        setVermietungs_Artikel(true);
                    }else{
                        setVermietungs_Artikel(false);
                    }

                    if (event.target.selectedOptions[0].innerHTML === "Bitte wählen"){
                        setButton_hinzufügen(true);
                    }else{
                        setButton_hinzufügen(false);
                    }

                }}>
                    <option data-vermietung={true}>Bitte wählen</option>
                    {Artikel.map((item ,index) => {
                      if (!String(item.name).startsWith(item.warengruppe)){
                        return (

                          <option key={`Arikel-auswahl-${index}`} data-vermietung={item.vermietung === undefined ? false : true} data-artikel_id={item.id}>{item.name}</option>
                          )
                      }
           })}
            </select>

            {!vermietungs_Artikel ?
            <div> 
              {/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
              <div class="value-button" id="decrease" onClick={ () => {
                if (count > 1){
                    setCount(count - 1);
                }
                
              }}
               value="Decrease Value">-</div>
              <input defaultValue={count} type="number" id="number" value={count} />
              {/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
              <div class="value-button" id="increase" onClick=
              {() => {
                setCount(count + 1)
              }} value="Increase Value">+</div>
              </div>
              : null}


<div>
<Button variant="contained" disabled={Button_hinzufügen} id="hinzufügen" form='Artikel'  type="submit" >hinzufügen</Button>

</div>
                </form >

            <div >
           
            </div>
            </div>
            : "Keine Daten"
            }
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}