import React , { useEffect  , useRef, useState} from "react";
import axios from "axios";


import CurrencyInput from 'react-currency-input-field';
import "./Artikel.css";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Artikel_bearbeiten_hinzufügen from "../Modal/Artikel_hinzufügen";
import AddCircleIcon from '@mui/icons-material/AddCircle';



export default function Artikel() {

     



const [artikel, setArtikel] = useState(undefined);
const [Warengruppen, setWarengruppe] = useState(undefined);
const [edit, setEdit] = useState(undefined);
const [Modal_Artikel, setModal_Artikel] = useState(false);






const getArtikel = async () => {
    const response = await axios.get("/api/Produkte");
    setArtikel(response.data);
};

const getWarengruppe = async () => {
    const response = await axios.get("/api/Warengruppen");
    setWarengruppe(response.data);
};



useEffect(() => {
  if (Modal_Artikel === false){
    getWarengruppe(); 
    getArtikel();
  }

    

   
    }, [edit, Modal_Artikel])

  



return ( 
    // Mitte des Bildschirms
    <div >
        {/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div style={{background : "ligth-gray", "border" : "1px solid black" , width : "max-content" , margin : "auto" , padding : "3px" , borderRadius : "10%"}} onClick={
    () => {
        setEdit(undefined);
        setModal_Artikel(true);
    }
}><AddCircleIcon/><label style={{display : "block"}}>Artikel hinzufügen</label></div>

        {artikel !== undefined  && Warengruppen !== undefined?
            <div>
            <table key={Math.random()} style={{margin : "auto"}}>
                <thead>
                <tr key="Artikelkopf">
                    <th>Name</th>
                    <th>Warengruppe</th>
                    <th>Preis</th>
                    <th>Sichtbarkeit</th>
  
                </tr>
                </thead>
                <tbody>
                {artikel.map((artikel) => {
                    return (

                        <tr key={`Artikel-${artikel.id}`}>
                            <td  key={artikel.uuid}>{artikel.name}</td>
                            <td >
                               
                               { artikel.warengruppe} 
    </td>
    <td><CurrencyInput disabled={true} form={`Artikel-${artikel.id}`} name="Preis" data-name={artikel.name} data-vermietung={JSON.stringify( artikel.vermietung)} data-warengruppe={artikel.warengruppe}  data-id={artikel.id} style={{width : "50px" , "textAlign": "center"}} maxLength={100} defaultValue={artikel.preis} intlConfig={{ locale: 'de-DE', currency: 'EUR' }} /></td>

    <td>
    <select style={{ "textAlign": "center"}}  defaultValue={artikel.Eigenschaften !== null ? artikel.Eigenschaften : 1} disabled={true}>
            <option value="1">überall sichtbar</option>
            <option value="2">nur bei Vermietungen</option>
            <option value="3">nur in Aufträgen</option>
        </select>
    </td>
    <td>
        {artikel.vermietung !== undefined ?
    <Tooltip title={`Mindestmietdauer : ${artikel.vermietung.mindestmietdauer} , Mieteinheit : ${artikel.vermietung.mieteinheit}`}>
  <InfoIcon/>
</Tooltip>
        : <></>}

    

    </td>
    {/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
    <td onClick={()=>{
        setEdit(artikel);
        setModal_Artikel(true);

    }}>
    <EditIcon />
    </td>
    { String(artikel.name).includes("Standart") === false    ?     
    // rome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
<td onClick={() => {
        // Cofirm
        if (!window.confirm("Artikel wirklich löschen?")) return;
        axios.delete(`/api/Produkte/?id=${artikel.id}`).then((response) => {
            getArtikel();
            getWarengruppe();
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }}>
    <DeleteForeverIcon />
    </td> : <></>}



                        
                        </tr>

                    );
                })}
</tbody>
            </table>



            </div>
:null}
{Modal_Artikel === true ?
<Artikel_bearbeiten_hinzufügen open={Modal_Artikel} close={() => {setModal_Artikel(false)}} Warengruppen={Warengruppen} Artikel={edit} alleArtikel={artikel} />
: null}

 
    </div>
);
}