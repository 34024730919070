import React , { useEffect  , useRef, useState} from "react";
import axios from "axios";
import Grundeinstellung from "../Modal/Grundeinstellung";
import User from "../Modal/User";
import Button from '@mui/material/Button';
import "./Einstellung.css"
import  Inventar  from "../Modal/Inventar";
export default (probs) => {

const [Inventar_B , setOpenInventar] = useState(false);
const [Grundeinstellung_B, setOpenGrundeinstellung] = useState(false);
const [User_B, setOpenUser] = useState(false);


return (
    <div className="Einstellung">
        <Button variant="contained" onClick={() => {setOpenGrundeinstellung(true)}}>Grundeinstellung</Button>
        <Button variant="contained" onClick={() => {setOpenUser(true)}}>Benutzer</Button>
        <Button variant="contained" onClick={() => {setOpenInventar(true)}}>Inventar</Button>
<Grundeinstellung key={`Grundeinstellung-${Grundeinstellung_B}`} open={Grundeinstellung_B} close={() => {setOpenGrundeinstellung(false)}}/> 

<User key={`User-${User_B}`} open={User_B} close={() => {setOpenUser(false)}}/> 
<Inventar key={`Inventar-${Inventar_B}`} open={Inventar_B} close={() => {setOpenInventar(false)}}/>



    </div>
)
}