// Modal from MUI
import Box from '@mui/material/Box';
import React,  {useState , useEffect} from 'react';
import axios from 'axios';
import { Modal} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import UserModal from "../Modal/add_User"
import "./User.css"
const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Grundeinstellung(props) {

    const [User , setUser] = useState(undefined)
    const [open, setOpen] = useState(false);

    const getUser =async () => {
        let response = await axios.get("/api/User?all=true");
        setUser(response.data);
    }

    useEffect(() => {
        if (props.open){
            getUser();
        }else if (open === false){
            getUser();
        }else{
            setUser(undefined);

        }
        },[props.open , open]);


// Email: Gruppe: Username:

return (


    <div >
        {User === undefined ?null :
        <Modal open={props.open} onClose={() => {props.close()}}>
        <Box sx={style}>
          

            <div className='User-Table'>
            <Button variant="contained" onClick={() => {setOpen(true)}}>Add User</Button>
            <UserModal open={open} close={() => {setOpen(false)}}/>
                <table >
                    <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Gruppe</th>
                    </tr>
                    </thead>
                    <tbody>
                    {User.map((item) => {
                        return (
                            <tr key={`user-${item.Email}-username-${item.Username}`}>
                                 <td>{item.Username}</td>
                                <td>
                                    <Tooltip title={item.Email} placement="top">
                                        <InfoIcon/>
                                    </Tooltip>
                                    </td>
                                <td>{item.Gruppe}</td>
                               
                                {/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                                <td>{item.Gruppe > 0 ? <div onClick={()=>{
                                    if (!window.confirm(`Sicher das der Benutzer ${item.Username} gelöscht werden soll ?`)) return;

                                    axios.delete(`/api/User?Username=${item.Username}`);
                                    getUser();
                                }}><DeleteForeverIcon /></div>  : null}</td>
                            </tr>
                        )
                    })}
                    </tbody>

                </table>
             

            </div>
</Box>
        </Modal>

                }
    </div>
)
}