import Box from '@mui/material/Box';
import axios from 'axios';
import "./Warenkorb.css"
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import React, { useState ,  useRef ,useEffect } from 'react';
import Button from '@mui/material/Button';
import QRCode from "react-qr-code";
import moment from 'moment-timezone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function (props) {

    const [Warenkorb, setWarenkorb] = useState(undefined);
    const [alternativArtikel, setAlternativArtikel] = useState(undefined);
    const [selectedArtikel, setSelectedArtikel] = useState(undefined);
    const [QR_Code , set_QRCode] = useState(undefined);
    const Endzeit = useRef(undefined);

    const Warengruppen  = (Warengruppe) => 
    {

        axios.get(`/api/Produkte?Warengruppe=${Warengruppe}`, {credentials: "same-origin"}).then((response) => {
            setAlternativArtikel(response.data);
        }).catch((error) => {
            console.log(error);
            setAlternativArtikel(undefined);
        }
        )

    }


    const alternativeArtikel = (Artikelid) => {
        axios.put("/api/Vermietung", {
            uuid : Warenkorb.uuid , Artikelid :Artikelid }).then((response) => {
                let Endzeit_lt_db  = moment(Warenkorb.vermietung.Warenkorb_abruf).tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm");
                let Endzeit_lt_user = moment(document.getElementById("Endzeit").value).tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm");
                if (Endzeit_lt_db !== Endzeit_lt_user) {
                    axios.post("/api/Vermietung", {    uuid : Warenkorb.uuid,
                        Checkout : false,
                        Vorgang : "Abschluss", 
                        Endzeit : document.getElementById("Endzeit").value }).then((response) => {
                            setWarenkorb(response.data);
                            setSelectedArtikel(response.data.vermietung.Artikelid);
                }).catch((error) => {
                    console.log(error);
                }
                )
                }else{
                    Rufe_Warenkorb_ab()
                }
            console.log(response);
        }).catch((error) => {
            console.log(error);

        })
    }

    const Rufe_Warenkorb_ab = () => {
    
        axios.get(`/api/Vermietung?uuid=${props.uuid}`, {credentials: "same-origin"}).then((response) => {
            let Objekt = response.data
            Objekt.vermietung.Warenkorb_abruf = moment().tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm");
            setWarenkorb(response.data);

            setSelectedArtikel(response.data.vermietung.Artikelid);
            if (alternativArtikel === undefined) Warengruppen(response.data.warengruppe);
        }).catch((error) => {
            console.log(error);
            setWarenkorb(undefined);
        })
    }

    useEffect(() => {
        if (props.open) {
            Endzeit.current = moment().tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm");
        }else{
            Endzeit.current = undefined;
        }
    }, [props.open])


    useEffect(() => {
        if (props.status === false) return;
        Rufe_Warenkorb_ab();
    },[props.status])

   const handleClose = () => {
        setAlternativArtikel(undefined);
        setWarenkorb(undefined);
        set_QRCode(undefined);
        props.close()}



    return (
        <div>
    
          <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                {QR_Code !== undefined ? <div className='QR-Code'><QRCode value={QR_Code} /> </div>
                
                : 
                <div>
                {Warenkorb !== undefined ? <p>{Warenkorb.name}</p> : "Fehler beim Laden"}
               {
                Warenkorb !== undefined ?
                <div>
                    {Abschließen()}

                    {Artikel()}
                    {Übersicht()                    }
                </div>
                
                : <p>Fehler beim Laden</p>
               }
                </div>}


            </Box>
            </Modal>
        </div>
    );

    function Abschließen (){
        return (
            <div className='abschließen'>
                <Button variant="contained" onClick={() => {
                    axios.post("/api/Vermietung", {    uuid : Warenkorb.uuid,
                    Checkout : true,
                    Vorgang : "Abschluss", 
                    Endzeit : document.getElementById("Endzeit").value }).then((response) => {
                        let Hostname = response.data.Hostname;
                        set_QRCode(`${Hostname}/?uuid=${response.data.Beleg_UUID}`);
                        console.log(response);
                    }).catch((error) => {
                        console.log(error);
                    })}}>QR-Code</Button>
                <Button variant="contained" onClick={() => {
                    axios.post("/api/Vermietung", {  
                          uuid : Warenkorb.uuid,
                          Vorgang : "Abschluss", 
                    Checkout : true,
                    Endzeit : document.getElementById("Endzeit").value
                }).then((response) => {
                    let Hostname = response.data.Hostname;
                    setTimeout(() => {
                    window.open(`${Hostname}/?uuid=${response.data.Beleg_UUID}`,'_blank');})
                    handleClose();
                        console.log(response);
                    }).catch((error) => {
                        console.log(error);
                    })}}>PDF-Dokument</Button>
                <Button variant="contained" onClick={() => {
                    axios.post("/api/Vermietung", {  
                          uuid : Warenkorb.uuid,
                          Vorgang : "Abschluss",
                            Checkout : true,
                            Endzeit : document.getElementById("Endzeit").value
                }).then((response) => {
                    handleClose();
                        console.log(response);
                    }).catch((error) => {
                        console.log(error);
                    })}}>Beenden</Button>
            </div>
        )
    }

    function Artikel (){
        if (alternativArtikel === undefined) {
            return (
                <div>
                    <p>Warengruppe wird geladen</p>
                </div>
            )
        }else{

        

        return (
            <div className='Kopf'>
                                <label>Startzeit
                                <input id="Startzeit" key={`Startzeit-${moment().tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm:SS")}`} type="datetime-local" defaultValue={moment(Warenkorb.vermietung.startzeit).tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm")}  onChange={
                                    (e)=> {
                                        let Value = e.target.value;
                                        axios.get(`/api/Vermietung?uuid=${Warenkorb.uuid}&Startzeit=${Value}`).then((response) => {
                                            setWarenkorb(response.data);
                                        }).catch((error) => {
                                            console.log(error);
                                        });



                                    }
                                } />
                                </label>
                    <label>Endzeit

                    <input id="Endzeit" type="datetime-local" defaultValue={Endzeit.current}  onChange={
                        (event) => {
                            axios.post("/api/Vermietung", {    uuid : Warenkorb.uuid,
                                Checkout : false,
                                Vorgang : "Abschluss", 
                                Endzeit : document.getElementById("Endzeit").value }).then((response) => {
                                setWarenkorb(response.data);

                    }).catch((error) => {
                        console.log(error);
                    }
                    )
                }}/>
                </label>
                <label>Artikel
                <select key={`Vermietungsartikel-${moment().format("HH:SS")}`} value={selectedArtikel} id="artikel" onChange={(event) => alternativeArtikel(event.target.value)}>
                    {alternativArtikel.map((artikel) => {
                        return (
                            <option  value={artikel.id}>{artikel.name}</option>
                        )
                    })}
                </select>
                </label>
                
            </div>
        )}
    }


    function Übersicht (){
        let Artikelpreis = Warenkorb.ArtikelPreis !== undefined ? Warenkorb.ArtikelPreis : 0;
        return (
            <div>

                <div className='Summen'>
                    <p>Endsumme: {Warenkorb.Zwischensummme.toLocaleString("de-DE", {style:"currency", currency:"EUR"})}</p>
                    <p>Vermietungspreis: {(Warenkorb.Zwischensummme - Artikelpreis).toLocaleString("de-DE", {style:"currency", currency:"EUR"})}</p>
                    {Warenkorb.ArtikelPreis !== undefined ? <p>Artikelpreis: {Artikelpreis.toLocaleString("de-DE", {style:"currency", currency:"EUR"})}</p> : null}

                </div>


                <table className='Warenkorb'>
                    <thead>
                        <tr>
                            <th>Artikel</th>
                            <th>Menge</th>
                            <th>Preis</th>
                            <th>Summe</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Warenkorb.positionen.map((artikel) => {
                            return (
                                <tr key={artikel.id}>
                                    <td>{artikel.name}</td>
                                    <td>{artikel.Menge}</td>
                                    <td>{artikel.preis.toLocaleString("de-DE", {style:"currency", currency:"EUR"})}</td>
                                    <td>{artikel.Summe.toLocaleString("de-DE", {style:"currency", currency:"EUR"})}</td>
                                    <td><DeleteForeverIcon onClick={
                                        () => {
                                            axios.delete(`/api/Vermietung/?uuid=${Warenkorb.uuid}&Artikeluuid=${artikel.uuid}`,).then((response) => {
                                                Rufe_Warenkorb_ab();
                                            }).catch((error) => {
                                                console.log(error);
                                            })
                                        }
                                    }/> </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
                
            </div>
        )
    }

}