import React ,{ useState} from "react";
import axios from "axios";
import moment from "moment-timezone"
import "./Belege.css"
import QRCode from "react-qr-code";


export default function (props) {

const [Belege ,seBelege] = useState(undefined);
const [Beleg , setBeleg] = useState(undefined);
const [QR_Code , set_QRCode] = useState(undefined);
const [Belegnummerbutton , setBelegnummerbutton] = useState(true);



const Rufe_Beleg_ab = (uuid) => {
    axios.get(`/api/Beleg?uuid=${uuid}&object=true`).then((response) => {
        setBeleg(response.data.Base64);
    }).catch((error) => {
        console.log(error);
    })


}

const Rufe_QR_Code_ab = (uuid) => {
    let key = "Host";
    axios.get(`/api/Settings?Wert=${key}`).then((response) => {
        let Hostname = response.data;
        set_QRCode(`${Hostname}/?uuid=${uuid}`);
    }).catch((error) => {
        console.log(error);
    })

}

return (

    <div className="container">

        <div className="Datumsauswahl">
            <label>
                Startdatum:
                <input type="date" id="Startdatum" name="Startdatum" defaultValue={moment().format("YYYY-MM-DD")}/>
            </label>
            <label>
                Enddatum:
                <input type="date" id="Enddatum" name="Enddatum" defaultValue={moment().format("YYYY-MM-DD")}/>
            </label>
            <button onClick={() => {
                setBeleg(undefined);
                axios.get(`/api/Belege?Startdatum=${document.getElementById("Startdatum").value}&Enddatum=${document.getElementById("Enddatum").value}`).then((response) => {
                    seBelege(response.data);
                }).catch((error) => {
                    console.log(error);
                })
            }}>Belege abrufen</button>
        </div>



        {
            QR_Code !== undefined ? 
            
            <div className="Beleg-QR">
                <div>
                {Belege !== undefined ?
                            
                            <button className="BelegButton"  onClick={() => {
                            set_QRCode(undefined);
                        }}>Zurück</button> : null}
                </div>
              
            
                <QRCode value={QR_Code} />
            
            </div> : 


        
        Beleg !== undefined ? 

        <div className="Beleg">
            {Belege !== undefined ?<button className="BelegButton" onClick={() => {
                setBeleg(undefined);
            }}>Zurück</button> : null}
            <iframe src={`${Beleg}#toolbar=0&navpanes=0&scrollbar=0`}   />
        </div>

        
        :

        <div className="Belege">
            {Belege !== undefined ? 
            Belege.length > 0 ?
            <table>
                <thead>
                    <tr>
                        <th>BelegNr.</th>
                        <th>Summe</th>
                        <th>Datum</th>
                        </tr>
                </thead>
                <tbody>
                    {Belege !== undefined ? Belege.map((beleg) => {
                        return (
                            <tr>
                                <td>{beleg.Belegnummer}</td>
                                <td>{beleg.Belegsumme.toLocaleString("de-DE", {style:"currency", currency:"EUR"})}</td>
                                <td>{moment(beleg.Datum).format("DD.MM.YYYY HH:MM")}</td>
                                <td><button onClick={() => {
                                    Rufe_Beleg_ab(beleg.UUID);
                                }}>Beleg abrufen</button></td>
                                <td><button onClick={() => {
                                    Rufe_QR_Code_ab(beleg.UUID);
                                }}>QR-Code abrufen</button></td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table>
            : <p>Keine Belege gefunden</p>
            : null}
        </div>
}


</div>

    )


}