import React, { useState , useEffect } from 'react';
import Login from './login';
import axios from 'axios';
import Home from './Tabs/Home';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Artikel from './Tabs/Artikel';
import 'react-tabs/style/react-tabs.css';
import Einstellung from "./Tabs/Einstellung"
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ProfilEinstellung from './Tabs/Profil_Einstellung';
import Belege from './Tabs/Belege';
export default function Übersicht() {

    const [User, setUser] = useState(undefined);

    const logout = function () {
        axios.get("/api/logout").then((response) => {
            setUser(undefined);
        })
    }

    useEffect(() => {

      axios.get("/api/login",{


      
      }).then((response) => {
        setUser(response.data);}).catch((error) => {
        console.log(error);
          setUser(undefined);
      })


            },[])



  return (
    <div>
      {User === undefined ? 
        <div>
            <Login setUser={setUser}/>

        </div>
      :
        <div>
            <Tabs>
    <TabList >
      <Tab id="Home">Home</Tab>
      <Tab id="Artikel">Artikel</Tab>
      <Tab id="Belege">Belege</Tab>
      <Tab id="Einstellung"><SettingsIcon/></Tab>
      <Tab id="User"><AccountBoxIcon/></Tab>
      <Tab id="Logout" onClick={() => {axios.get("/api/logout").then((response) => {
                setUser(undefined);})}}><LogoutIcon/></Tab>
    </TabList>
    <TabPanel>
      <Home/>
    </TabPanel>
    <TabPanel>
      <Artikel/>
    </TabPanel>
    <TabPanel>
      <Belege/>
    </TabPanel>
    <TabPanel>
    <Einstellung/>
    </TabPanel>
    <TabPanel>
    <ProfilEinstellung logout={logout} User={User}/>


    </TabPanel>
    <TabPanel>
    </TabPanel>
  </Tabs>




        </div>
      }
    </div>
  );
}



