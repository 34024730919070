// Modal from MUI
import Box from '@mui/material/Box';
import React,  {useState , useEffect} from 'react';
import axios from 'axios';
import { Modal} from '@mui/material';
import Button from '@mui/material/Button';
import Inventar_add_edit from "./Inventar_add_edit"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "./Inventar.css"
const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Grundeinstellung(props) {

    const [Settings , setSettings] = useState(undefined);
    const [open, setOpen] = useState(false);

    const getSettings = async () => {

        let response = await axios.get("/api/Inventar");
        setSettings(response.data);

    }
    useEffect(() => {

        getSettings();
    },[props.open , open ]);

return (


    <div >

        <Modal open={props.open} onClose={() => {props.close()}}>
        <Box sx={style}>
            {Settings === undefined ? <div>loading</div> :
            <div>
                <Button onClick={() => {setOpen(true)}}>Einrichtung hinzufügen</Button>
                <Inventar_add_edit open={open} close={() => {setOpen(false)}}/>

                <div className="Inventareinstellung" >
                    <div className="Inventar">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>anzahl</th>
                                </tr>
                            </thead>
                            <tbody key={JSON.stringify(Settings)}>
                                {Object.keys(Settings).map((item) => {
                                    return (
                                        <tr>
                                            <td>{item}</td>
                                            <td>{Settings[item]}</td>
                                            <td><DeleteForeverIcon onClick={
                                                async () => {
                                                    // Confirm
                                                    if (!window.confirm(`Sind Sie sich sicher das sie ${item} löschen möchten ?`)) {
                                                        return;
                                                    }
                                                    await axios.delete("/api/Inventar/", {data: {Objektname: item}});
                                                    getSettings();
                                                }
                                            }/></td>
                                            <td><EditIcon/></td>

                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                        
                        </div>
           


                </div>
            </div>
}
</Box>
        </Modal>

    </div>
)
}