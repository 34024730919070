// Modal from MUI
import Box from '@mui/material/Box';
import React,  {useState , useEffect} from 'react';
import axios from 'axios';
import { Modal} from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import "./Inventar_add_edit.css"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "./add_User.css"
const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Grundeinstellung(props) {


 
return (


    <div >

        <Modal open={props.open} onClose={() => {props.close()}}>
        <Box sx={style}>
           
          
                <div className="Add_User" >
              <form autocomplete="off" id="Add_User" onSubmit={
                (event) => {
                    event.preventDefault();
                    let Data =     {
                        Username : event.target.Username.value,
                        Password : event.target.Password.value,
                        Email : event.target.Email.value,
                        Name : event.target.Name.value,
                        Gruppe :   parseInt( event.target.Gruppe.value),
                        Config : {}
                    
                    }
                    axios.post("/api/User", Data).then((response) => {
                        console.log(response.data);
                        props.close();
                    }).catch((error) => {
                        console.log(error);
                    }
                    );
                    
                }
              } >
                <label>
                    Username:
                    <input required type="text" name="Username" />
                </label>
                <label> Name:
                    <input required type="text" name="Name" />
                </label>
                <label>
                    Password:
                    <input required type="text" name="Password" />
                </label>
                <label>
                    Email:
                    <input required type="text" name="Email" />
                </label>
                <label>
                    Gruppe:
                    <select name="Gruppe">
                        <option value="30">Admin</option>
                        <option value="40">Mitarbeiter</option>
                        <option value="50">Wirtschaftsprüfer</option>

                    </select>
                </label>


               
                <input className='button' type="submit" value="hinzufügen" />
              </form >
                </div>

           



</Box>
        </Modal>

    </div>
)
}