import axios from 'axios';
import React, { useState , useRef , useEffect } from 'react';
import Artikel_Modal_Box from "../Modal/Artikel";
import Warenkorb from '../Modal/Warenkorb';
import "./Home.css";


export default (probs) => {
    // Use Ref
    const Inventar_Gegenstand = useRef({uuid : undefined, vermietet : undefined, name : undefined,});

    const [Inventar , setInventar] = useState(undefined);
    const [Artikel_Modal , setArtikel_Modal] = useState(false);
    const [Warenkorb_Modal , setWarenkorb_Modal] = useState(false);
    const id_Polling = useRef(undefined);
    const uuid = useRef(undefined);

    const abrufen = ()=> {
        axios.get("/api/Vermietung",{credentials: "same-origin"
        }).then((response) => {
            setInventar(response.data);

        
        }).catch((error) => {
            console.log(error);
            setInventar(undefined);
        })
    }

    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === "visible" && id_Polling.current === undefined) {
            id_Polling.current = setInterval(() => { 
                abrufen();
            }, 5000);
        }else if(id_Polling.current !== undefined){
            clearInterval(id_Polling.current);
            id_Polling.current = undefined;
        }

    });

    useEffect(() => {
        if (id_Polling.current === undefined) {
            id_Polling.current = setInterval(() => { 
                abrufen();
            }, 10000);
        }
        return () => {
            clearInterval(id_Polling.current);
            id_Polling.current = undefined;
        }
        

    },[])



    useEffect(() => {
        abrufen();
    },[Warenkorb_Modal , Artikel_Modal])




    // Vermietung beginnen oder Warenkopb aufrufen
    const Status = (e) => {

        if(e.target.dataset.vermietet === "false" || e.target.dataset.vermietet === false){
        axios.patch("/api/Vermietung", { uuid: e.target.dataset.uuid}).then((response) => {
            if(response.status === 200){
                abrufen();
            }
        }).catch((error) => {})
    }else{
        console.log("Warenkorb");

        uuid.current = e.target.dataset.uuid
        setWarenkorb_Modal(true);
    }

    }

    const Artikel = (e) => {
        
        Inventar_Gegenstand.current = {uuid : e.target.dataset.uuid,
            vermietet : e.target.dataset.vermietet,
            name : e.target.dataset.name,}
        setArtikel_Modal(true);
    }


    window.document.addEventListener("visibilitychange", function(e) {
        if (window.document.visibilityState === "visible") {
            abrufen();
        }

      });
    window.document.body.style.zoom = 1;
    return(
        <div className='Übersicht'>
            {Inventar === undefined ?
                <div>
                    <h1>Keine Daten</h1>
                    </div>
                    : 
                    
                    <div>
                        {Artikel_Modal === true ? <Artikel_Modal_Box open={Artikel_Modal} details={Inventar_Gegenstand} close={() => {setArtikel_Modal(false)}}/>: null}
                      {Warenkorb_Modal === true ? <Warenkorb uuid={uuid.current} status={Warenkorb_Modal} open={Warenkorb_Modal} close={() => {setWarenkorb_Modal(false)}} /> : null}
                        <table className="table table-striped">
                            <tbody className="thead-dark">
                            {
                                Inventar.map((item , index) => {
                                    return(
                                        <tr key={`Inventar_Tr_${index}`}>
                                            <td data-uuid={item.uuid} ><button data-uuid={item.uuid} data-vermietet={item.Zwischensummme !== undefined ? true : false}  onClick={Status} id={`Inventar-${index}`} >{item.name}</button></td>
                                            <td><button data-name={item.name} data-uuid={item.uuid} data-vermietet={item.Zwischensummme !== undefined ? true : false} onClick={item.Zwischensummme === undefined ? Status : Artikel}> {item.Zwischensummme === undefined ? "Unvermietet" : item.Zwischensummme.toLocaleString("de-DE", {style:"currency", currency:"EUR"}) }</button></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>


                    </div>
                    
                    }
        </div>
    )



}