// Modal from MUI
import Box from '@mui/material/Box';
import React,  {useState , useEffect} from 'react';
import axios from 'axios';
import { Modal} from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import "./Inventar_add_edit.css"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Grundeinstellung(props) {

 
return (


    <div >

        <Modal open={props.open} onClose={() => {props.close()}}>
        <Box sx={style}>
           
          
                <div className="detail_Inventar" >
              <form autocomplete="off" id="InventarFormular" onSubmit={
                (event) => {
                    event.preventDefault();
                    let data = {
                        Objektname: event.target.Objektname.value,
                        anzahl: parseInt( event.target.anzahl.value),
                        preis: parseFloat(event.target.preis.value.replace(",",".").replace("€","")),
                        mindestmietdauer: parseInt(event.target.mindestmietdauer.value),
                        mieteinheit: parseInt(event.target.mieteinheit.value),

                    }
                    axios.post("/api/Inventar", data).then((response) => {
                        console.log(response.data);
                        props.close();


                    }).catch((error) => {
                        console.log(error);
                    });
                }
              } >
                <label>
                    Name:
                    <input required type="text" name="Objektname" />
                </label>
                <label>
                    Anzahl:
                    <input required type="text" name="anzahl" />
                </label>
                <label>
                    Preis:
                    <CurrencyInput intlConfig={{ locale: 'de-DE', currency: 'EUR' }} required type="preis" name="preis" />
                </label>
                <label>
                    Mindestmietdauer:
                    <CurrencyInput required type="text" name="mindestmietdauer" />
                </label>

                <label>
                    Mieteinheit:
                    <CurrencyInput required type="text" name="mieteinheit" />
                </label>
                <input className='button' type="submit" value="hinzufügen" />
              </form >
                </div>

           



</Box>
        </Modal>

    </div>
)
}