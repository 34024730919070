import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CurrencyInput from 'react-currency-input-field';
import { useEffect } from 'react';

// MUI Modal

const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function (props){

    const [vermietung, setvermietung] = useState(undefined);
    const [Artikel, setArtikel] = useState(props.Artikel);

    const addArtikel = (event) => {

        event.preventDefault();
        // leeren Felder erlauben
        let Vorgang = event.target.art.value
        if (event.target.name.value === "" || event.target.preis.value === "" || event.target.warengruppe.value === "") {
            return}
        if (props.alleArtikel !== undefined && Vorgang === "hinzufügen") {
            for (let i = 0; i < props.alleArtikel.length; i++) {
                if (props.alleArtikel[i].name === event.target.name.value) {
                    window.alert("Artikel bereits vorhanden");
                    return;
                }
            }
        }
        
        let Preis = parseFloat(String(event.target.preis.value).replace(" €", "").replace(",", "."));
        // Finde data-id aus Datalist heraus mithilfe von  event.target.warengruppe.value 
        let vermietung = event.target.vermietung.checked  === true ? {
            mindestmietdauer : event.target.mindestmietdauer.value,
            mieteinheit : event.target.mieteinheit.value,
    
        } : undefined;

        if (vermietung !== undefined) {
            if (vermietung.mindestmietdauer === "0" || vermietung.mieteinheit === "0") {
                window.alert("Die Mindesmietdauer und die Mieteinheit müssen größer als 0 sein");
                return
            }}
    
    
        let Artikel = {
            Vorgang : Vorgang,
            id : props.Artikel !== undefined ? props.Artikel.id : undefined,
            Name : event.target.name.value,
            Preis : Preis,
            Warengruppe : event.target.warengruppe.value,
            Eigenschaften : parseInt( event.target.eigenschaften.value),
            vermietung : vermietung  
    
            }
        // Wenn kein Name , Preis oder Warengruppe angegeben
        if (Artikel.Name === "" || Artikel.Preis === 0 || Artikel.Warengruppe === ""  ) {
            window.alert("Bitte alle Felder ausfüllen");
            return
        }
        if (Artikel.Name.includes("Standart") && Vorgang === "hinzufügen") {
            window.alert("Die Bezeichnung Standart ist nicht erlaubt");
            return
        }
    

        axios.post("/api/Produkte",  Artikel).then((response) => {

            props.close()
            console.log(response);
        }).catch((error) => {
            console.log(error);
        }
        )

   
    


        
    
    }


    useEffect(() => {
        setvermietung(props.Artikel !== undefined ? props.Artikel.vermietung : undefined)
    }, [props.Artikel]);



    return (
    <Modal open={props.open} onClose={() => { props.close()}} >
<div className="NeuerArikel">
<form  onSubmit={ addArtikel}>
<table  style={{margin : "auto"}}>
    <tbody>
    <tr>
        <td>Bezeichnung : </td>
        <td><input autoComplete='off' disabled={props.Artikel !== undefined ? true : false} defaultValue={props.Artikel !== undefined ?props.Artikel.name : null } required type="text" name="name" /></td>
    </tr>
    <tr>
        <td>Warengruppe : </td>
        <td>
        <input disabled={props.Artikel !== undefined ? props.Artikel.name.includes(props.Artikel.warengruppe)? true: false : false}  defaultValue={props.Artikel !== undefined ?props.Artikel.warengruppe : ""}  required type="text" name="warengruppe" list="warengruppen" onChange={(e) =>{
     
            let Wert = e.target.value;
            props.Warengruppen.map((warengruppe) => {
                if (warengruppe.name === Wert) {
                    setvermietung(warengruppe.vermietung);
                }
            })
        }}/>
    <datalist id="warengruppen">
    {props.Warengruppen.map((warengruppe) => {
                    return (
                        <option key={warengruppe.name} data-vermietung={JSON.stringify(warengruppe.vermietung)} value={warengruppe.name}>{warengruppe.name}</option>
                    );
                })}
    </datalist>

            
        </td>
    </tr>
    <tr>
        <td>Preis : </td>
        <td><CurrencyInput defaultValue={props.Artikel !== undefined ? props.Artikel.preis : 0}  name="preis" style={{ "textAlign": "center"}} maxLength={100}  intlConfig={{ locale: 'de-DE', currency: 'EUR' }} required /></td>
    </tr>
    <tr>
        <td>Sichtbarkeit : </td>
        <td>        
            <select style={{ "textAlign": "center"}} defaultValue="1" name="eigenschaften">
            <option value="1">überall sichtbar</option>
            <option value="2">nur bei Vermietungen</option>
            <option value="3">nur in Aufträgen</option>
        </select></td>
    </tr>
    <tr>
        <td>Vermietung : </td>
        <td>
        <input disabled={vermietung !== undefined ? vermietung.mieteinheit !== undefined? true: false : false} checked={vermietung !== undefined ? true : false}  type="checkbox" name="vermietung" onChange={
            (e) => {
                if(e.target.checked){
                    setvermietung(true);
                }else{
                    setvermietung(undefined);
                }
            }
        }   />
        </td>
    </tr>
    {vermietung !== undefined ?
    <>
    <tr>
        <td>Mindestmietdauer : </td>
        <td>
            <CurrencyInput defaultValue={vermietung !== undefined ? vermietung.mindestmietdauer !== undefined ?vermietung.mindestmietdauer : 0  : 0} name="mindestmietdauer" style={{ "textAlign": "center"}} maxLength={2}  />
        </td>
        </tr>

        <tr>
        <td>Mieteinheit : </td>
        <td>

            <CurrencyInput defaultValue={vermietung !== undefined ? vermietung.mieteinheit !== undefined ?vermietung.mieteinheit : 0  : 0} name="mieteinheit" style={{ "textAlign": "center"}} maxLength={2}  />
        </td>


    </tr>
    </>
    : null}

    <tr>
        <td><input className="button" name="art" type="submit" value={props.Artikel !== undefined ? "ändern" : "hinzufügen"} /></td>


    </tr>
    </tbody>
</table>
                </form>
                </div>

    </Modal>
    )
}